<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="代理商姓名：">
        <el-input size="small" v-model="searchForm.name" placeholder="请输入代理商姓名"></el-input>
      </el-form-item>
      <el-form-item label="代理商电话：">
        <el-input size="small" v-model="searchForm.mobile" placeholder="请输入代理商电话"></el-input>
      </el-form-item>
      <el-form-item label="公司名称：">
        <el-input size="small" v-model="searchForm.company_name" placeholder="请输入公司名称"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="正常" :value="1"></el-option>
          <el-option label="审核中" :value="0"></el-option>
          <el-option label="清退" :value="-1"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="限制wifi：">
        <el-select v-model="searchForm.is_wifi_limit_vaild" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="未限制" :value="0"></el-option>
          <el-option label="已限制" :value="1"></el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="限制激励：">
        <el-select v-model="searchForm.is_store_limit_vaild" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="未限制" :value="0"></el-option>
          <el-option label="已限制" :value="1"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="注册日期：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="是否统计：">
        <el-select v-model="searchForm.is_statistics" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="统计" :value="1"></el-option>
          <el-option label="不统计" :value="0"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label=" " label-width="15px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button size="small" @click="exportList">导出</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="total-num">
      <div>筛选后总收益：{{ total_amount }}</div>
      <div>筛选后总余额：{{ amount }}</div>
      <div>筛选后总提现金额：{{ withdrawal_amount }}</div>
    </div>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="代理商姓名" align="center" width="150"></el-table-column>
      <el-table-column prop="mobile" label="代理商电话" align="center" width="120"></el-table-column>
      <el-table-column prop="integral_amount" label="额度" align="center"></el-table-column>
      <el-table-column prop="grade" label="授权级别" width="90" align="center">
        <template v-slot="{ row }">
          {{ row.grade == 1 ? '市级' : row.grade == 2 ? '区级' : row.grade == 3 ? '省级' : '异常' }}
        </template>
      </el-table-column>
      <el-table-column prop="province_name" label="授权区域" align="center" width="150">
        <template v-slot="{ row }">{{ row.province_name }}{{ row.city_name }}{{ row.area_name || '' }}</template>
      </el-table-column>
      <el-table-column prop="company_name" label="公司名称" align="center" width="200"></el-table-column>
      <el-table-column prop="business_license" label="营业执照" align="center" width="180">
        <template v-slot="{ row }">
          <el-image style="width: 140px; height: 70px" :src="row.business_license" :preview-src-list="[row.business_license]"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="commission_ratio" label="代理商佣金比例" align="center" width="130">
        <template v-slot="{ row }">{{ row.commission_ratio }}%</template>
      </el-table-column>
      <el-table-column prop="rate" label="代理商费率比例" align="center" width="130">
        <template v-slot="{ row }">{{ row.rate }}%</template>
      </el-table-column>
      <el-table-column prop="total_qrcode" label="总计二维码数" align="center" width="120"></el-table-column>
      <el-table-column prop="regimental_total" label="推广合伙人数" align="center" width="110"></el-table-column>
      <el-table-column prop="store_num" label="推广商家数" align="center" width="110"></el-table-column>
      <el-table-column prop="total_amount" align="center" width="110">
        <template #header>
          <div @click="sortList('total_amount')" class="sortList">
            <div>总收益</div>
            <i :style="{ color: orderBy == 'total_amount' ? '#409EFF' : '' }" class="el-icon-caret-bottom"></i>
          </div>
        </template>
        <template v-slot="{ row }">￥{{ row.total_amount }}</template>
      </el-table-column>
      <el-table-column prop="amount" align="center" width="110">
        <template #header>
          <div @click="sortList('amount')" class="sortList">
            <div>当前余额</div>
            <i :style="{ color: orderBy == 'amount' ? '#409EFF' : '' }" class="el-icon-caret-bottom"></i>
          </div>
        </template>
        <template v-slot="{ row }">￥{{ row.amount }}</template>
      </el-table-column>
      <el-table-column prop="withdrawal_amount" align="center" width="120">
        <template #header>
          <div @click="sortList('withdrawal_amount')" class="sortList">
            <div>累计提现金额</div>
            <i :style="{ color: orderBy == 'withdrawal_amount' ? '#409EFF' : '' }" class="el-icon-caret-bottom"></i>
          </div>
        </template>
        <template v-slot="{ row }">￥{{ row.withdrawal_amount }}</template>
      </el-table-column>
      <el-table-column prop="act_num" label="已激活二维码数" align="center" width="130"></el-table-column>
      <el-table-column prop="status" label="状态" align="center" width="80">
        <template v-slot="{ row }">
          {{ row.status == 1 ? '正常' : row.status == 0 ? '审核中' : '清退' }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="注册时间" align="center" width="180">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="260">
        <template v-slot="{ row }">
          <el-button @click="operation(row, 1)" type="text" size="small">编辑</el-button>
          <el-button @click="operation(row, 5)" type="text" size="small">{{ row.is_black ? '恢复' : '拉黑' }}</el-button>
          <el-button @click="operation(row, 2)" type="text" size="small" v-if="row.status == 1">清退</el-button>
          <el-button @click="operation(row, 3)" type="text" size="small">查看明细</el-button>
          <el-button @click="operation(row, 4)" type="text" size="small" v-if="row.status == 0">审核</el-button>
          <el-button type="text" size="small" @click="openChangeDialog(row, 1)">增加额度</el-button>
          <el-button type="text" size="small" @click="openDetailsDialog(row)">额度明细</el-button>
          <!-- <el-button @click="operation(row, 6)" type="text" size="small">{{ row.is_wifi_limit_vaild ? '取消限制wifi' :
                        '限制wifi' }}</el-button>
                    <el-button @click="operation(row, 7)" type="text" size="small">{{ row.is_store_limit_vaild ? '取消限制激励' :
                        '限制激励' }}</el-button>
                    <el-button @click="operation(row, 8)" type="text" size="small">{{ row.is_limit_user_link_wifi ?
                        '取消限制连续wifi激励' : '限制连续wifi激励' }}</el-button>

                    <el-button @click="operation(row, 10)" type="text" size="small">{{ row.is_tencent ? '关闭腾讯系' : '开启腾讯系'
                    }}</el-button> 
                    <el-button @click="operation(row, 9)" type="text" size="small" style="color: #f56c6c">{{
                        row.is_statistics ? '统计' : '不统计' }}</el-button>
                    <el-button @click="operation(row, 11)" type="text" size="small">{{
                        row.is_effective_merchants ? '关闭有效商家' : '开启有效商家' }}</el-button>
                    <el-button type="text" size="small" @click="operation(row, 12)">单独wifi分润</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog :title="addForm.id ? '编辑' : '添加'" :visible.sync="showAdd" width="600px">
      <el-form ref="form" :model="addForm" :rules="rules" label-width="120px">
        <el-form-item label="代理商姓名：" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="代理商电话：" prop="mobile">
          <el-input v-model="addForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="公司名称：" prop="company_name">
          <el-input v-model="addForm.company_name"></el-input>
        </el-form-item>
        <el-form-item label="代理商佣金比例" prop="commission_ratio">
          <el-input v-model="addForm.commission_ratio">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="代理商费率比例" prop="rate">
          <el-input v-model="addForm.rate">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="增加二维码数：" prop="total_qrcode">
          <el-input v-model="addForm.total_qrcode"></el-input>
        </el-form-item>
        <el-form-item label="营业执照：" prop="business_license">
          <ReadyUploadSource
            :showStyle="{
              width: '150px',
              height: '100px',
            }"
            @getSource="val => (addForm.business_license = val.path)"
            :path="addForm.business_license"
            @removeThis="() => (addForm.business_license = '')"
          ></ReadyUploadSource>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmAdd">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核" :visible.sync="showAudit" width="600px">
      <el-form label-width="130px">
        <el-form-item label="代理商佣金比例：">
          <el-input v-model="auditForm.commission_ratio" max="100">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="限制商家数量：">
          <el-switch v-model="auditForm.is_limit_store" :inactive-value="0" :active-value="1"></el-switch>
        </el-form-item>
        <el-form-item label="商家二维码数：" v-if="auditForm.is_limit_store">
          <el-input v-model="auditForm.store_num"></el-input>
        </el-form-item>
        <el-form-item label="限制合伙人数量：">
          <el-switch v-model="auditForm.is_limit_regimental" :inactive-value="0" :active-value="1"></el-switch>
        </el-form-item>
        <el-form-item label="发展合伙人数：" v-if="auditForm.is_limit_regimental">
          <el-input v-model="auditForm.regimental_num"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAudit = !1">取 消</el-button>
        <el-button type="primary" @click="audit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看明细" :visible.sync="showDetailed" width="780px">
      <el-table :data="balanceList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="create_time" label="生成时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="收益支出" align="center">
          <template v-slot="{ row }">
            {{ dealStatus(Number(row.type)) }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="预估收益" align="center">
          <template v-slot="{ row }">{{ row.income_type == 1 ? '+' : '-' }}{{ row.amount }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="balance_total" :page="balance_page" :pageNum="balance_rows" @updatePageNum="balanceUpdateData"></Paging>
    </el-dialog>

    <el-dialog title="单独wifi分润" :visible.sync="wifi_visible" width="780px">
      <el-input v-model="set_wifi.wifi_price" placeholder="请输入内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="wifi_visible = false">取 消</el-button>
        <el-button type="primary" @click="setWifiPrice">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 转账弹窗 -->
    <el-dialog title="增加额度" :visible.sync="showChangeDialog" width="600rpx">
      <el-form label-width="120px" class="demo-ruleForm">
        <el-form-item label="金额：">
          <el-input v-model="change_info.amount" placeholder="请输入金额"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showChangeDialog = false">取 消</el-button>
        <el-button type="primary" @click="sureChange">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 转账明细 -->
    <el-dialog :visible.sync="change_details_info_dialog" width="780px">
      <template #title>
        <div>
          <span>增加额度明细</span>
          <el-button style="margin-left: 12px" size="small" @click="exportFn">导出</el-button>
        </div>
      </template>
      <el-table :data="change_details_info.list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column align="center" label="代理商名称">
          <template v-slot="{ row }">{{ row.facilitator_info.name }}</template>
        </el-table-column>
        <el-table-column label="增加额度">
          <template v-slot="{ row }">{{ row.integral_amount }}</template>
        </el-table-column>
        <el-table-column label="时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.create_time) }}
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="change_details_info.total" :page="change_details_info.page" :pageNum="change_details_info.rows" @updatePageNum="detailsUpdateData"></Paging>
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        company_name: '',
        start_time: '',
        end_time: '',
        status: '',
        is_wifi_limit_vaild: '',
        is_store_limit_vaild: '',
        is_statistics: '',
      },
      addForm: {
        name: '',
        mobile: '',
        company_name: '',
        business_license: '',
        commission_ratio: '',
        rate: '',
        total_qrcode: '',
      },
      rules: {
        name: { required: true, message: '请填写代理商姓名', trigger: 'change' },
        mobile: { required: true, message: '请填写代理商电话', trigger: 'blur' },
        company_name: { required: true, message: '请填写公司名称', trigger: 'blur' },
        business_license: { required: true, message: '请添加营业执照', trigger: 'change' },
        commission_ratio: { required: true, message: '请填写代理商佣金比例', trigger: 'blur' },
        rate: { required: true, message: '请填写代理商费率比例', trigger: 'blur' },
        total_qrcode: { required: true, message: '请填写增加二维码数', trigger: 'blur' },
      },
      auditForm: {
        is_limit_store: 0,
        store_num: '',
        is_limit_regimental: 0,
        regimental_num: '',
        commission_ratio: '',
      },
      showAdd: !1,
      id: '',
      list: [],
      balanceList: [],
      total: 0,
      showAudit: !1,
      showDetailed: !1,
      balance_page: 1,
      balance_rows: 10,
      balance_total: 0,
      // 排序方式
      orderBy: '',
      total_amount: '',
      amount: '',
      withdrawal_amount: '',
      wifi_visible: false,
      set_wifi: {
        id: '',
        wifi_price: '',
      },
      achievement_data_row: {},
      change_details_info_dialog: false,
      change_details_info: {
        page: 1,
        rows: 10,
        total: 0,
        list: [],
      },
      change_info: {
        amount: '',
      },
      showChangeDialog: false,
      details_dialog_info: {
        1: {
          title: '查看转账明细',
          money: 'integral',
        },
        2: {
          title: '查看转账释放积分明细',
          money: 'integral',
        },
        3: {
          title: '查看转账业绩明细',
          money: 'sales_amount',
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    html_open_fn() {
      this.$router.push('/finance/downCenter');
    },
    exportFn() {
      let that = this;
      let obj = {
        id: this.achievement_data_row.id,
      };
      let url = this.$api.yiyun.exportIntegralAmount;
      this.$axios.post(url, obj).then(res => {
        if (res.code === 0) {
          this.$alert(`<div>导出中，请耐心等待，稍后跳转到<span id="html_open" style='color:#409EFF;cursor: pointer;'>导出文件</span>中下载</div>`, '提示', {
            dangerouslyUseHTMLString: true,
          });
          this.$nextTick(() => {
            let span_btn = document.querySelectorAll('#html_open')[0];
            span_btn.removeEventListener('click', that.html_open_fn);
            span_btn.addEventListener('click', that.html_open_fn);
          });
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    sureChange() {
      if (!this.change_info.amount) {
        this.$message.warning('请填写金额');
        return;
      }
      let url = this.$api.yiyun.tranferIntegralAmount;
      this.$axios
        .post(url, {
          integral_amount: this.change_info.amount,
          id: this.achievement_data_row.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('增加成功');
            this.showChangeDialog = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    openChangeDialog(data) {
      this.change_info.amount = '';
      this.achievement_data_row = data;
      this.showChangeDialog = true;
    },
    detailsUpdateData(val, status) {
      if (status == 0) {
        this.change_details_info.rows = val;
      } else {
        this.change_details_info.page = val;
      }
      this.getDetailsList();
    },
    openDetailsDialog(data) {
      this.achievement_data_row = data;
      this.getDetailsList(1);
      this.change_details_info_dialog = true;
    },
    getDetailsList(style) {
      if (style) {
        this.change_details_info.page = 1;
      }
      let url = this.$api.yiyun.IntegralAmountLog;
      let obj = {
        page: this.change_details_info.page,
        rows: this.change_details_info.rows,
        id: this.achievement_data_row.id,
      };
      this.$axios.post(url, obj).then(res => {
        if (res.code == 0) {
          this.change_details_info.list = res.result.list;
          this.change_details_info.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    dealStatus(type) {
      switch (type) {
        case 1:
          return 'wifi';
          break;
        case 2:
          return '第三方活动';
          break;
        case 3:
          return '扫码点餐';
          break;
        case 4:
          return '提现';
          break;
        case 5:
          return '全真支付';
          break;
        case 6:
          return '连接激励奖励';
          break;
        case 7:
          return '消费激励奖励';
          break;
        case 8:
          return 'CPS商品分润';
          break;
        case 10:
          return '话费';
          break;
        case 20:
          return '直充';
          break;
        case 30:
          return '吃喝卡券';
          break;
        case 40:
          return '电费';
          break;
        case 50:
          return '广告金';
          break;
        case 60:
          return '小程序上线奖励';
          break;
        case 70:
          return '点餐流水奖励';
          break;
        case 80:
          return '配送抽佣奖励';
          break;
        case 90:
          return '贴码视频奖励';
          break;
        default:
          return '未知状态';
          break;
      }
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        company_name: '',
        start_time: '',
        end_time: '',
        status: '',
        is_wifi_limit_vaild: '',
        is_store_limit_vaild: '',
        is_statistics: '',
      };
      this.orderBy = '';
      this.getList();
    },
    balanceUpdateData(val, status) {
      if (status == 0) {
        this.balance_rows = val;
      } else {
        this.balance_page = val;
      }
      this.getBalanceList();
    },
    sortList(key) {
      this.orderBy = this.orderBy == key ? '' : key;
      this.searchForm.page = 1;
      this.getList();
    },
    exportList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      delete searchForm.rows;
      delete searchForm.page;
      this.$axios.post(this.$api.serviceProvider.serviceProviderList, { ...searchForm, is_export: 1 }).then(res => {
        if (res.code == 0) {
          let a = document.createElement('a');
          a.href = res.result;
          a.id = 'download';
          document.body.appendChild(a);
          a.click();
          let aDom = document.getElementById('download');
          document.body.removeChild(aDom);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      if (this.searchForm.is_wifi_limit_vaild === -1) searchForm.is_wifi_limit_vaild = null;
      if (this.searchForm.is_store_limit_vaild === -1) searchForm.is_store_limit_vaild = null;
      if (this.orderBy) {
        searchForm.order = this.orderBy;
      } else {
        delete searchForm.order;
      }
      this.$axios.post(this.$api.serviceProvider.serviceProviderList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total = res.result.total;
          this.total_amount = res.result.total_amount || 0.0;
          this.amount = res.result.amount || 0.0;
          this.withdrawal_amount = res.result.withdrawal_amount || 0.0;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getBalanceList() {
      this.$axios
        .post(this.$api.serviceProvider.BalanceLog, {
          page: this.balance_page,
          rows: this.balance_rows,
          identity_type: 1,
          facilitator_id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showDetailed = !0;
            this.balanceList = res.result.list;
            this.balance_total = res.result.total_number;
            console.log(res);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    comfirmAdd() {
      if (this.addForm.commission_ratio > 100) {
        return this.$message.error('百分比不能超过100');
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post(this.addForm.id ? this.$api.serviceProvider.editServiceProvider : this.$api.serviceProvider.addServiceProvider, this.addForm).then(res => {
            if (res.code == 0) {
              this.$message.success(this.addForm.id ? '编辑成功' : '添加成功');
              this.getList();
              this.showAdd = !1;
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    audit() {
      if (this.auditForm.commission_ratio > 100) {
        return this.$message.error('百分比不能超过100');
      }
      let data = {
        id: this.id,
        status: 1,
        commission_ratio: this.auditForm.commission_ratio,
        is_limit_store: this.auditForm.is_limit_store,
        is_limit_regimental: this.auditForm.is_limit_regimental,
      };
      if (!data.commission_ratio) return this.$message.warning('请填写佣金比例');
      if (data.is_limit_store) {
        data.store_num = this.auditForm.store_num;
        if (!data.store_num) return this.$message.warning('请填写商家二维码数');
      }
      if (data.is_limit_regimental) {
        data.regimental_num = this.auditForm.regimental_num;
        if (!data.regimental_num) return this.$message.warning('请填写发展合伙人数');
      }
      this.$axios.post(this.$api.serviceProvider.auditServiceProvider, data).then(res => {
        if (res.code == 0) {
          this.$message.success('处理成功');
          this.showAudit = !1;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    operation(row, type) {
      if (type == 1) {
        this.addForm.id = row.id;
        this.addForm.name = row.name;
        this.addForm.mobile = row.mobile;
        this.addForm.company_name = row.company_name;
        this.addForm.business_license = row.business_license;
        this.addForm.commission_ratio = row.commission_ratio;
        this.addForm.total_qrcode = row.total_qrcode;
        this.addForm.rate = row.rate;
        this.showAdd = !0;
      }
      if (type == 2) {
        this.$confirm('请确认是否清退该代理商？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$axios
            .post(this.$api.serviceProvider.serviceProviderCheck, {
              id: row.id,
              status: -1,
            })
            .then(res => {
              if (res.code == 0) {
                this.$message.success('清退成功');
                row.status = -1;
              } else {
                this.$message.error(res.msg);
              }
            });
        });
      }
      if (type == 3) {
        this.id = row.id;
        this.balance_page = 1;
        this.getBalanceList();
      }
      if (type == 4) {
        this.auditForm = {
          is_limit_store: 0,
          store_num: '',
          is_limit_regimental: 0,
          regimental_num: '',
          commission_ratio: '',
        };
        this.id = row.id;
        this.showAudit = !0;
      }
      if (type == 5) {
        if (row.is_black) {
          this.$axios
            .post(this.$api.serviceProvider.facilitatorBlack, {
              id: row.id,
              is_black: 0,
            })
            .then(res => {
              if (res.code == 0) {
                row.is_black = 0;
                this.$message.success('已恢复');
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          this.$confirm('请确认是否拉黑该代理商？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$axios
              .post(this.$api.serviceProvider.facilitatorBlack, {
                id: row.id,
                is_black: 1,
              })
              .then(res => {
                if (res.code == 0) {
                  row.is_black = 1;
                  this.$message.success('已拉黑');
                } else {
                  this.$message.error(res.msg);
                }
              });
          });
        }
      }
      if (type == 6) {
        this.$axios
          .post(this.$api.serviceProvider.isWifiLimitVaild, {
            is_wifi_limit_vaild: row.is_wifi_limit_vaild ? 0 : 1,
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              row.is_wifi_limit_vaild = row.is_wifi_limit_vaild ? 0 : 1;
            } else {
              this.$message.error(res.msg);
            }
          });
      }
      if (type == 7) {
        this.$axios
          .post(this.$api.serviceProvider.isStoreLimitVaild, {
            is_store_limit_vaild: row.is_store_limit_vaild ? 0 : 1,
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              row.is_store_limit_vaild = row.is_store_limit_vaild ? 0 : 1;
            } else {
              this.$message.error(res.msg);
            }
          });
      }
      if (type == 8) {
        this.$axios
          .post(this.$api.serviceProvider.isLimitUserLinkWifi, {
            is_limit_user_link_wifi: row.is_limit_user_link_wifi ? 0 : 1,
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              row.is_limit_user_link_wifi = row.is_limit_user_link_wifi ? 0 : 1;
            } else {
              this.$message.error(res.msg);
            }
          });
      }
      if (type == 9) {
        this.$axios
          .post(this.$api.serviceProvider.isStatisticsFacilitator, {
            id: row.id,
            is_statistics: row.is_statistics ? 0 : 1,
          })
          .then(res => {
            if (res.code == 0) {
              row.is_statistics = row.is_statistics ? 0 : 1;
            } else {
              this.$message.error(res.msg);
            }
          });
      }
      if (type == 10) {
        this.$axios
          .post(this.$api.serviceProvider.isTencent, {
            is_tencent: row.is_tencent ? 0 : 1,
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              row.is_tencent = row.is_tencent ? 0 : 1;
              this.$message.success(row.is_tencent ? '已开启' : '已关闭');
            } else {
              this.$message.error(res.msg);
            }
          });
      }
      if (type == 11) {
        this.$axios
          .post(this.$api.serviceProvider.isEffectiveMerchants, {
            is_effective_merchants: row.is_effective_merchants ? 0 : 1,
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              row.is_effective_merchants = row.is_effective_merchants ? 0 : 1;
              this.$message.success(row.is_effective_merchants ? '已开启' : '已关闭');
            } else {
              this.$message.error(res.msg);
            }
          });
      }
      if (type == 12) {
        this.set_wifi = {
          wifi_price: row.wifi_price,
          id: row.id,
        };
        this.wifi_visible = true;
      }
    },
    setWifiPrice() {
      this.$axios.post(this.$api.serviceProvider.setWifiPrice, this.set_wifi).then(res => {
        if (res.code == 0) {
          this.$message.success('设置成功');
          this.wifi_visible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;

  .total-num {
    display: flex;
    font-weight: bold;
    margin: 20px 0;

    div {
      margin-right: 40px;
    }
  }

  .sortList {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
